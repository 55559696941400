// :: globals
// :: Define all global variables inside this folder
module.exports = {
  // :: App
  appConfig: {
    name: "CombiQ",
    // devMode: process.env.NODE_ENV === "development",
    devMode: false, // since no env variables where configure by default we show
  },
}
