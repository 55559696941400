const state = {
  menuInstance: null,
}
const getters = {
  menuInstance(state) {
    return state.menuInstance
  },
}
const mutations = {
  setMenuInstance(state, payload) {
    state.menuInstance = payload
  },
}
const actions = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
