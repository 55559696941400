import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store/store"
import axios from "axios"
import "@/plugins/highcharts"
import Vuelidate from "vuelidate"
import i18n from "./i18n"
import GlobalFunctions from "@/plugins/globalFunctions.js"
import "material-design-icons-iconfont/dist/material-design-icons.css"
import "@mdi/font/css/materialdesignicons.css"
import "@fortawesome/fontawesome-free/css/all.css"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"
import ElementUI from "element-ui"
import { ElementTiptapPlugin } from "element-tiptap"
import "element-ui/lib/theme-chalk/index.css"
import "element-tiptap/lib/index.css"

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg" || "mdi" || "md",
  },
})

require("materialize-css/dist/css/materialize.css")
require("materialize-css/dist/js/materialize.js")

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(GlobalFunctions, store)
axios.defaults.headers.get.Accept = "application/json"
Vue.use(ElementUI)
Vue.use(ElementTiptapPlugin, {
  lang: "en",
})

new Vue({
  router,
  store,
  i18n,
  vuetify: new Vuetify(),
  render: (h) => h(App),
}).$mount("#app")
