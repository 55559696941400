import router from "../../router"
const state = {}
const getters = {}
const mutations = {}
const actions = {
  logErrorMessage(store, data) {
    if (data && data.display !== false) {
      M.toast({
        html: router.app.$i18n.t(data.error.message),
        displayLength: 10000,
      })
    }
  },
  showMessage(store, message) {
    if (message) {
      M.toast({
        html: message,
        displayLength: 10000,
      })
    }
  },
  showQuickMessage(store, message) {
    if (message) {
      M.toast({
        html: message,
        displayLength: 1000,
      })
    }
  },
  showNotification(store, message) {
    if (message) {
      const toastHtml =
        "<span>" +
        message +
        "</span><button class='cq-button cq-button-default btn notification-btn' onclick='M.Toast.dismissAll()'>" +
        router.app.$i18n.t("NotificationsCloseAll") +
        "</button>"
      M.toast({
        html: toastHtml,
        displayLength: 10000,
      })
    }
  },
}
export default {
  state,
  mutations,
  actions,
  getters,
}
