/* eslint-disable no-template-curly-in-string */
import axios from "axios"
import EndPoints from "./endpoints"
import store from "./store/store"
import router from "./router"

class Error {
  constructor(code, message, retry) {
    this.code = code
    this.message = message
    this.retry = retry
  }

  toString() {
    return this.message + this.code
  }
}
const httpMethods = ["options", "get", "head", "delete", "patch", "post", "put"]
const ERROR_NOT_AUTHENTICATED = 401
const ERROR_NOT_AUTHORIZED = 403
const defaultOptions = {
  axios: {
    method: "get",
    responseType: "json",
  },
  errors: {
    "-400": new Error(-400, "error.missing_parameters", false),
    0: new Error(0, "error.no_response", true),
    400: new Error(400, "error.incorrect_request", false),
    404: new Error(404, "error.not_found", false),
    500: new Error(500, "error.server_error", false),
    [ERROR_NOT_AUTHENTICATED]: new Error(ERROR_NOT_AUTHENTICATED, "error.not_authenticated", false),
    [ERROR_NOT_AUTHORIZED]: new Error(ERROR_NOT_AUTHORIZED, "error.no_permission", false),
  },
  disableRetry: false,
  maxRetries: 5,
  minimumRetryTime: 1000,
}
const handleError = (error, options, axiosOptions, promise, attempts = 1) => {
  let errorCode = 0
  let errorMessage = null
  if (error && error.response) {
    errorCode = parseInt(error.response.status) || 500
    if (errorCode === ERROR_NOT_AUTHENTICATED) {
      store.dispatch("logout")
      promise.reject(options.errors[errorCode])
      return
    }
    const errorData = error.response.data
    // Error message sent by the server has precedence over local error messages
    // If the server wants to send a custom error message, the html body response
    // expected is a json with this format: {"errorCode": "errorCode", "message":"errorMessage"}
    if (errorData && errorData.message) {
      errorMessage = new Error(errorCode, errorData.message, false)
    } else {
      errorMessage = options.errors[errorCode]
    }
  } else {
    // No response from server, set the default 'no response' error code
    errorMessage = options.errors[errorCode]
  }
  errorMessage = errorMessage || options.errors[500]
  if (errorMessage.retry && attempts <= options.maxRetries) {
    const cooldownTime = options.minimumRetryTime * Math.pow(2, attempts - 1)
    setTimeout(function () {
      axios
        .request(axiosOptions)
        .then((res) => promise.resolve(res))
        .catch((error) => handleError(error, options, axiosOptions, promise, attempts + 1))
    }, cooldownTime)
  } else {
    promise.reject(errorMessage)
  }
}
const request = (methodOptions, url, template, apiOptions) => {
  template = Object.assign({}, template)
  return (parameters, invokerOptions) => {
    if (!(parameters instanceof FormData)) parameters = Object.assign({}, parameters)

    const options = Object.assign({}, defaultOptions, methodOptions, apiOptions, invokerOptions)
    for (const parameter in template) {
      if (typeof template[parameter] === "string") {
        if (parameters instanceof FormData) {
          parameters.append(parameter, template[parameter])
        } else {
          parameters[parameter] = template[parameter]
        }
        continue
      }
      if (!template[parameter]) continue
      if (parameters instanceof FormData) {
        if (!parameters.has(parameter)) return Promise.reject(options.errors[-400])
      } else {
        if (
          parameters[parameter] !== 0 &&
          (parameters[parameter] === undefined || parameters[parameter] === null)
        )
          return Promise.reject(options.errors[-400])
      }
    }
    const axiosOptions = Object.assign({}, options.axios)
    axiosOptions.url = typeof url === "function" ? url(parameters) : url

    if (httpMethods.indexOf(options.axios.method) > 3) {
      axiosOptions.data = parameters
    } else {
      axiosOptions.params = parameters
    }
    if (
      store.state.auth.currentCompanyAssignment &&
      store.state.auth.currentCompanyAssignment.token
    ) {
      if (!axiosOptions.headers) axiosOptions.headers = {}

      if ("headers" in options)
        Object.keys(options.headers).forEach((key) => {
          axiosOptions.headers[key] = options.headers[key]
        })

      axiosOptions.headers.Authorization =
        "Bearer " + store.state.auth.currentCompanyAssignment.token
    }
    return new Promise((resolve, reject) => {
      axios
        .request(axiosOptions)
        .then((res) => resolve(res))
        .catch((error) => handleError(error, options, axiosOptions, { resolve, reject }))
    })
  }
}
const httpDeleteMethod = request.bind(null, { axios: { method: "delete" } })
const httpGetMethod = request.bind(null, { axios: { method: "get" } })
const httpPatchMethod = request.bind(null, { axios: { method: "patch" } })
const httpPostMethod = request.bind(null, { axios: { method: "post" } })
// eslint-disable-next-line no-unused-vars
const httpPutMethod = request.bind(null, { axios: { method: "put" } })
const getBinary = request.bind(null, { axios: { method: "get", responseType: "arraybuffer" } })
const getBlob = request.bind(null, { axios: { method: "get", responseType: "blob" } })

const dynamicUrl = (strings, ...keys) => {
  return (parameters) => {
    if (!parameters) parameters = {}
    let result = strings[0]
    for (let i = 1; i < strings.length; i++) {
      const key = keys[i - 1] || ""
      // Decide if it's either a static string, or parametrized
      // It's looking for the following format: ${parameter_name}
      const matches = /^\${(.+)}$/.exec(key)
      if (matches && matches[1]) {
        const parameter = matches[1].trim()
        if (parameters instanceof FormData) {
          if (parameters.has(parameter)) {
            result += parameters.get(parameter)
            parameters.delete(parameter)
          }
        } else {
          result += parameters[parameter] || ""
          if (parameters[parameter]) delete parameters[parameter]
        }
      } else {
        result += key
      }
      result += strings[i]
    }
    return result
  }
}

const PARAM_ID = "${_id}"
const PARAM_ID_1 = "${_id1}"
const PARAM_TYPE = "${_type}"
const required = true
const optional = false

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error && error.response) {
      const errorCode = parseInt(error.response.status)
      if (errorCode === ERROR_NOT_AUTHORIZED) {
        router.push({ name: "forbidden" })
      }
    }
    return Promise.reject(error)
  }
)

axios.interceptors.request.use(function (config) {
  if (request.url === dynamicUrl`${EndPoints.FederatedAuth}/api/Auth/Refresh`) {
    config.headers = {
      Authorization: `Bearer ${store.state.auth.refreshToken}`,
    }
  }
  return config
})

export default {
  alertStatus: {
    list: httpGetMethod(EndPoints.Management + "/notifications"),
    listArchives: httpGetMethod(EndPoints.Management + "/archives"),
    listStats: httpGetMethod(EndPoints.Analytics + "/notification-statatistics"),
    listdetail: httpGetMethod(dynamicUrl`${EndPoints.Management}/notifications/${PARAM_ID}`, {
      _id: required,
    }),
    getByUnitId: httpGetMethod(
      dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/notifications`,
      {
        _id: required,
      }
    ),
    acknowledged: httpPatchMethod(dynamicUrl`${EndPoints.Management}/notifications/${PARAM_ID}`, {
      _id: required,
      content: required,
      type: required,
      generatedDate: required,
      receivedDate: required,
      unit: required,
    }),
    delete: httpDeleteMethod(dynamicUrl`${EndPoints.Management}/notifications/${PARAM_ID}`, {
      _id: required,
    }),
  },
  assets: {
    list: httpGetMethod(EndPoints.Management + "/assets"),
    getById: httpGetMethod(dynamicUrl`${EndPoints.Management}/assets/${PARAM_ID}`, {
      _id: required,
    }),
    create: httpPostMethod(EndPoints.Management + "/assets", {
      identifier: required,
      description: required,
    }),
    delete: httpDeleteMethod(dynamicUrl`${EndPoints.Management}/assets/${PARAM_ID}`, {
      _id: required,
    }),
    edit: httpPatchMethod(dynamicUrl`${EndPoints.Management}/assets/${PARAM_ID}`, {
      _id: required,
      identifier: required,
      description: required,
    }),
    getReadingAggregation: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/assets/${PARAM_ID}/aggregations`,
      {
        _id: required,
        from: required,
        to: required,
        timeSpan: required,
        instanceType: required,
        sensorId: optional,
        dataType: optional,
        axis: optional,
      }
    ),
    linkUnits: httpPostMethod(dynamicUrl`${EndPoints.Management}/assets/${PARAM_ID}/units`, {
      _id: required,
      entityKey: required,
    }),
    unlinkUnits: httpDeleteMethod(
      dynamicUrl`${EndPoints.Management}/assets/${PARAM_ID}/units/${"${unitEntityKey}"}`,
      {
        _id: required,
        unitEntityKey: required,
      }
    ),
  },
  chargingSettings: {
    list: httpGetMethod(EndPoints.Management + "/api/Settings"),
  },
  companies: {
    getById: httpGetMethod(dynamicUrl`${EndPoints.Management}/companies/${PARAM_ID}`, {
      _id: required,
    }),
    list: httpGetMethod(EndPoints.Management + "/companies"),
    create: httpPostMethod(EndPoints.Management + "/companies", {
      company: required,
    }),
    delete: httpDeleteMethod(dynamicUrl`${EndPoints.Management}/companies/${PARAM_ID}`, {
      _id: required,
    }),
    edit: httpPatchMethod(dynamicUrl`${EndPoints.Management}/companies/${PARAM_ID}`, {
      _id: required,
      identifier: required,
      description: required,
      measurementSystem: required,
      timeZone: required,
      defaultBSConfigFile: optional,
      defaultISNConfigFile: optional,
      thresholdCustomizedNotification: optional,
      runningTimeDataType: optional,
    }),
    getByUserId: httpGetMethod(dynamicUrl`${EndPoints.Management}/users/${PARAM_ID}/companies`, {
      _id: required,
    }),
    linkUser: httpPostMethod(dynamicUrl`${EndPoints.Management}/companies/${PARAM_ID}/users`, {
      _id: required,
      identifier: required,
      emailAddress: required,
    }),
    unlinkUser: httpDeleteMethod(
      dynamicUrl`${EndPoints.Management}/companies/${PARAM_ID}/users/${"${userEntityKey}"}`,
      {
        _id: required,
        userEntityKey: required,
      }
    ),
    uploadNotificationImage: httpPostMethod(
      EndPoints.Management + "/notification-images",
      {
        file: required,
      },
      { headers: { "Content-Type": "multipart/form-data" } }
    ),
  },
  companyFeatures: {
    getCompanyFeatures: httpGetMethod(EndPoints.Management + "/company-features"),
    create: httpPostMethod(EndPoints.Management + "/company-features", {
      purchasableFeatureType: required,
      type: required,
      feature: required,
      isEnabled: required,
    }),
    edit: httpPatchMethod(dynamicUrl`${EndPoints.Management}/company-features/${PARAM_ID}`, {
      _id: required,
      feature: optional,
      isEnabled: required,
    }),
  },
  configurationFiles: {
    getById: httpGetMethod(
      dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/configuration-files`,
      {
        _id: required,
      }
    ),
    list: httpGetMethod(EndPoints.Management + "/configuration-files"),
    createUnitConfigurationFileLink: httpPostMethod(
      dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/configuration-files`,
      {
        _id: required,
        entityKey: required,
      }
    ),
    deleteUnitConfigurationFileLink: httpDeleteMethod(
      dynamicUrl`${
        EndPoints.Management
      }/units/${PARAM_ID}/configuration-files/${"${configurationFileEntityKey}"}`,
      {
        _id: required,
        configurationFileEntityKey: required,
      }
    ),
  },
  customUoms: {
    create: httpPostMethod(EndPoints.Management + "/custom-uoms", {
      dataType: required,
      conversionOperator: required,
      destinationUom: required,
    }),
    list: httpGetMethod(EndPoints.Management + "/custom-uoms"),
    delete: httpDeleteMethod(dynamicUrl`${EndPoints.Management}/custom-uoms/${PARAM_ID}`, {
      _id: required,
    }),
    getById: httpGetMethod(dynamicUrl`${EndPoints.Management}/custom-uoms/${PARAM_ID}`, {
      _id: required,
    }),
    edit: httpPatchMethod(dynamicUrl`${EndPoints.Management}/custom-uoms/${PARAM_ID}`, {
      _id: required,
      dataType: required,
      conversionOperator: required,
      destinationUom: required,
    }),
  },
  deactivatedUnits: {
    activateUnit: httpPutMethod(dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/activate`, {
      _id: required,
    }),
    getByIdentifier: httpGetMethod(
      dynamicUrl`${EndPoints.Management}/deactivated-units/${PARAM_ID}`,
      {
        _id: required,
      }
    ),
  },
  files: {
    list: httpGetMethod(EndPoints.FotaApp + "/detailed-files"),
    fileList: httpGetMethod(EndPoints.FotaApp + "/files"),
    getFile: getBinary(
      dynamicUrl`${EndPoints.FotaApp}/files/${PARAM_ID}`,
      {
        _id: required,
      },
      { headers: { Accept: "application/octet-stream" } }
    ),
    getPdfFile: getBinary(
      dynamicUrl`${EndPoints.FotaApp}/documents/${PARAM_ID}`,
      {
        _id: required,
      },
      { headers: { Accept: "application/pdf" } }
    ),
    getLinkedUnits: httpGetMethod(dynamicUrl`${EndPoints.FotaApp}/files/${PARAM_ID}/units`, {
      _id: required,
    }),
    createLink: httpPostMethod(dynamicUrl`${EndPoints.FotaApp}/files/${PARAM_ID}/units`, {
      _id: required,
      entityKey: required,
    }),
    deleteFile: httpDeleteMethod(dynamicUrl`${EndPoints.FotaApp}/files/${PARAM_ID}`, {
      _id: required,
    }),
    deleteLink: httpDeleteMethod(
      dynamicUrl`${EndPoints.FotaApp}/files/${PARAM_ID}/units/${"${unitEntityKey}"}`,
      {
        _id: required,
        unitEntityKey: required,
      }
    ),
    upload: httpPostMethod(
      EndPoints.FotaApp + "/files",
      {
        file: required,
      },
      { headers: { "Content-Type": "multipart/form-data" } }
    ),
    getLogsByUnit: httpGetMethod(dynamicUrl`${EndPoints.FotaApp}/units/${PARAM_ID}/fota-logs`, {
      _id: required,
      from: optional,
      to: optional,
    }),
    getFileInformation: httpGetMethod(
      dynamicUrl`${EndPoints.FotaApp}/files/${PARAM_ID}`,
      {
        _id: required,
      },
      { headers: { Accept: "application/json" } }
    ),
  },
  groups: {
    list: httpGetMethod(EndPoints.Management + "/groups"),
    getById: httpGetMethod(dynamicUrl`${EndPoints.Management}/groups/${PARAM_ID}`, {
      _id: required,
    }),
    create: httpPostMethod(EndPoints.Management + "/groups", {
      identifier: required,
      description: required,
    }),
    delete: httpDeleteMethod(dynamicUrl`${EndPoints.Management}/groups/${PARAM_ID}`, {
      _id: required,
    }),
    edit: httpPatchMethod(dynamicUrl`${EndPoints.Management}/groups/${PARAM_ID}`, {
      _id: required,
      identifier: required,
      description: required,
    }),
  },
  preferencesSet: {
    getByUserId: httpGetMethod(
      dynamicUrl`${EndPoints.Monitoring}/users/${PARAM_ID}/preferences-set`,
      {
        _id: required,
      }
    ),
    addOrUpdatePreference: httpPostMethod(
      dynamicUrl`${EndPoints.Monitoring}/users/${PARAM_ID}/preferences-set/${PARAM_ID_1}/preferences`,
      {
        _id: required,
        _id1: required,
        type: required,
        name: optional,
        value: optional,
      }
    ),

    // Not Restful
    select: httpGetMethod(
      dynamicUrl`${EndPoints.Monitoring}/api/PreferencesSetSelect/${PARAM_ID}`,
      {
        _id: required,
      }
    ),
    retrieve: httpGetMethod(dynamicUrl`${EndPoints.Monitoring}/api/PreferencesSetGetByUser`),
    getEmpty: httpGetMethod(dynamicUrl`${EndPoints.Monitoring}/api/PreferencesSetGetDefault`),
    update: httpPatchMethod(
      dynamicUrl`${EndPoints.Monitoring}/api/PreferencesSet/${"${entityKey}"}`,
      {
        entityKey: required,
        name: required,
      }
    ),
    create: httpPostMethod(EndPoints.Monitoring + "/api/PreferencesSet", {
      name: required,
    }),
    readPreference: httpGetMethod(EndPoints.Monitoring + "/api/PreferenceGetByName", {
      name: required,
      type: required,
    }),
    readPreferenceByType: httpGetMethod(
      dynamicUrl`${EndPoints.Monitoring}/api/Preference/${PARAM_TYPE}`,
      {
        _type: required,
      }
    ),
  },
  roles: {
    list: httpGetMethod(EndPoints.Management + "/roles"),
    getById: httpGetMethod(dynamicUrl`${EndPoints.Management}/roles/${PARAM_ID}`, {
      _id: required,
    }),
    create: httpPostMethod(dynamicUrl`${EndPoints.Management}/roles/${PARAM_ID}`, {
      identifier: required,
      description: optional,
      type: required,
      entityPermissions: required,
      featurePermissions: optional,
    }),
    delete: httpDeleteMethod(dynamicUrl`${EndPoints.Management}/roles/${PARAM_ID}`, {
      _id: required,
    }),
    edit: httpPatchMethod(dynamicUrl`${EndPoints.Management}/roles/${PARAM_ID}`, {
      _id: required,
      identifier: required,
      description: optional,
      type: required,
      entityPermissions: required,
      featurePermissions: optional,
    }),
    getByUser: httpGetMethod(dynamicUrl`${EndPoints.Management}/users/${PARAM_ID}/roles`, {
      _id: required,
    }),
    editUserAssignments: httpPutMethod(
      dynamicUrl`${EndPoints.Management}/roles/${PARAM_ID}/users`,
      {
        _id: required,
        userRoleLinkList: required,
      }
    ),
  },
  scheduledExports: {
    list: httpGetMethod(dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/scheduled-exports/`, {
      _id: required,
    }),
    getScheduledExport: httpGetMethod(
      dynamicUrl`${
        EndPoints.Analytics
      }/units/${PARAM_ID}/scheduled-exports/${"${exportEntityKey}"}`,
      {
        _id: required,
      }
    ),
    create: httpPostMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/scheduled-exports/`,
      {
        recurrence: required,
        runAt: required,
        timeRange: required,
        emails: required,
      }
    ),
    edit: httpPatchMethod(
      dynamicUrl`${
        EndPoints.Analytics
      }/units/${PARAM_ID}/scheduled-exports/${"${exportEntityKey}"}`,
      {
        _id: required,
        exportEntityKey: required,
        recurrence: required,
        runAt: required,
        timeRange: required,
        emails: required,
      }
    ),
    delete: httpDeleteMethod(
      dynamicUrl`${
        EndPoints.Analytics
      }/units/${PARAM_ID}/scheduled-exports/${"${exportEntityKey}"}`,
      {
        _id: required,
        exportEntityKey: required,
      }
    ),
  },
  signalr: {
    access: httpGetMethod(EndPoints.Analytics + "/endpoints"),
  },
  systemRoles: {
    list: httpGetMethod(EndPoints.Management + "/system-roles"),
    getById: httpGetMethod(dynamicUrl`${EndPoints.Management}/system-roles/${PARAM_ID}`, {
      _id: required,
    }),

    // Not Restful
    editUserAssignments: httpPutMethod(
      dynamicUrl`${EndPoints.Management}/system-roles/${PARAM_ID}/system-users`,
      {
        _id: required,
        userRoleLinkList: required,
      }
    ),
  },
  systemUsers: {
    list: httpGetMethod(EndPoints.Management + "/system-users"),
    getBySystemUserEntityKey: httpGetMethod(
      dynamicUrl`${EndPoints.Management}/system-users/${PARAM_ID}/system-roles`,
      {
        _id: required,
      }
    ),
    create: httpPostMethod(EndPoints.Management + "/system-users", {
      name: optional,
      description: optional,
      emailAddress: required,
      identifier: required,
      password: required,
      phoneNumber: optional,
    }),
    edit: httpPatchMethod(dynamicUrl`${EndPoints.Management}/system-users/${PARAM_ID}`, {
      _id: required,
      identifier: required,
      description: optional,
      name: optional,
      emailAddress: required,
      phoneNumber: optional,
    }),
    getBySystemRoleEntityKey: httpGetMethod(
      dynamicUrl`${EndPoints.Management}/system-roles/${PARAM_ID}/system-users`,
      {
        _id: required,
      }
    ),
    createSystemRoleLink: httpPostMethod(
      dynamicUrl`${EndPoints.Management}/system-users/${PARAM_ID}/system-roles`,
      {
        _id: required,
        entityKey: required,
      }
    ),
    deleteSystemRoleLink: httpDeleteMethod(
      dynamicUrl`${
        EndPoints.Management
      }/system-users/${PARAM_ID}/system-roles/${"${roleEntityKey}"}`,
      {
        _id: required,
        roleEntityKey: required,
      }
    ),
  },
  unifiedThresholds: {
    list: httpGetMethod(EndPoints.Management + "/thresholds"),
    getById: httpGetMethod(dynamicUrl`${EndPoints.Management}/thresholds/${PARAM_ID}`, {
      _id: required,
    }),
    delete: httpDeleteMethod(dynamicUrl`${EndPoints.Management}/thresholds/${PARAM_ID}`, {
      _id: required,
    }),
    edit: httpPatchMethod(dynamicUrl`${EndPoints.Management}/thresholds/${PARAM_ID}`, {
      identifier: required,
      description: optional,
      severity: required,
      type: required,
      sensorType: optional,
      dataType: optional,
      condition: optional,
      value: optional,
      topValue: optional,
      timeSpan: optional,
      enableNotifications: optional,
      unitSettings: optional,
      unitAssignments: optional,
      thresholdUsersForNotification: optional,
      userAssignments: optional,
    }),
    create: httpPostMethod(EndPoints.Management + "/thresholds", {
      identifier: required,
      description: optional,
      severity: required,
      type: required,
      sensorType: optional,
      dataType: optional,
      condition: optional,
      value: optional,
      topValue: optional,
      timeSpan: optional,
      enableNotifications: optional,
      unitSettings: optional,
      unitAssignments: optional,
      thresholdUsersForNotification: optional,
      userAssignments: optional,
    }),
    getByUnit: httpGetMethod(dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/thresholds/`, {
      _id: required,
    }),
    getThresholdEvents: httpGetMethod(dynamicUrl`${EndPoints.Analytics}/thresholdevents`, {
      from: required,
      to: required,
      sensorId: optional,
      dataType: optional,
      thresholdType: optional,
    }),
  },
  units: {
    list: httpGetMethod(EndPoints.Management + "/units"),
    detailedList: httpGetMethod(EndPoints.Management + "/units", {
      showAll: optional,
      showDetailed: required,
    }),
    getById: httpGetMethod(dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}`, {
      _id: required,
    }),
    getLatestCompensatedPressure: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/last-compensated-pressure`,
      {
        _id: required,
      }
    ),
    getLatestPressure: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/last-pressure`,
      {
        _id: required,
      }
    ),
    getLatestStroke: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/last-stroke`,
      {
        _id: required,
      }
    ),
    getLatestTemperature: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/last-temperature`,
      {
        _id: required,
      }
    ),
    getReadingsXslx: getBlob(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/readings`,
      {
        _id: required,
        from: required,
        to: required,
      },
      {
        headers: {
          Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    ),
    getReadings: httpGetMethod(dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/readings`, {
      _id: required,
      from: required,
      to: required,
    }),
    getEnergyConsumptionAggregation: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/aggregations`,
      {
        _id: required,
        from: required,
        to: required,
        timeSpan: required,
        aggregationType: required,
        instanceType: required,
      }
    ),
    getRunningTimeDownsampled: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/running-times-downsampled`,
      {
        _id: required,
        from: required,
        to: required,
        timeSpan: required,
        summarize: required,
        sensorId: optional,
        dataType: optional,
      }
    ),
    getStrokeCount: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/strokes-count`,
      {
        _id: required,
        groupType: required,
      }
    ),
    getStrokesPerMinute: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/strokes-per-minute`,
      {
        _id: required,
        from: required,
        to: required,
      }
    ),
    getTotalStrokes: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/total-strokes`,
      {
        _id: required,
        from: required,
        to: required,
        timeSpan: required,
      }
    ),
    getUnitDataXslx: getBlob(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/export-data`,
      {
        _id: required,
        from: required,
        to: required,
      },
      {
        headers: {
          Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    ),
    getUnitEventsXslx: getBlob(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/events`,
      {
        _id: required,
        from: required,
        to: required,
      },
      { headers: { Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" } }
    ),
    getUnitEvents: httpGetMethod(dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/events`, {
      _id: required,
      from: required,
      to: required,
    }),
    multipoint: {
      listSessions: httpGetMethod(
        dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/multipoints-metadata`,
        {
          _id: required,
        }
      ),
      getSession: httpGetMethod(dynamicUrl`${EndPoints.Analytics}/samples/${PARAM_ID}`, {
        _id: required,
      }),
    },
    edit: httpPatchMethod(dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}`, {
      _id: required,
      identifier: required,
      bootloader: required,
      hardwareVersion: required,
      application: required,
      type: required,
    }),
    createSettingsAndLink: httpPutMethod(
      dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/configuration`,
      {
        runningTimeOnLimit: optional,
        runnintTimeOffLimit: optional,
        chargingPressure: optional,
        chargingDate: optional,
        chargingTemperature: optional,
        minOperationalPressurePercentage: optional,
        installationDate: optional,
        lifetimeEstimationDays: optional,
        lifetimeEstimationStrokes: optional,
        strokesPerMinutePlanned: optional,
        enableEnergyMeasurementPort2: optional,
        installedClamp2: optional,
        inputVoltage2: optional,
        inputPhase2: optional,
        enableEnergyMeasurementPort3: optional,
        installedClamp3: optional,
        inputVoltage3: optional,
        inputPhase3: optional,
      }
    ),
    getReadingAggregation: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/aggregations`,
      {
        _id: required,
        from: required,
        to: required,
        timeSpan: required,
        aggregationType: required,
        instanceType: required,
        sensorId: optional,
        dataType: optional,
        axis: optional,
      }
    ),
    getNotificationsCount: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/notifications-count`,
      {
        _id: required,
        groupType: required,
      }
    ),
    getSettings: httpGetMethod(
      dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/configuration`,
      {
        _id: required,
      }
    ),
    getEstimatedMaintenance: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/last-estimated-maintenance`,
      {
        _id: required,
      }
    ),
    getHumidityData: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/last-humidity`,
      {
        _id: required,
      }
    ),
    getPowerData: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/last-electric-power`,
      {
        _id: required,
      }
    ),
    getProductionTime: httpGetMethod(
      dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/production-time`,
      {
        _id: required,
      }
    ),
    getUnitBIST: httpGetMethod(dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/bist`, {
      _id: required,
      from: required,
      to: required,
    }),
    getUnitMessages: httpGetMethod(dynamicUrl`${EndPoints.Analytics}/units/${PARAM_ID}/messages`, {
      _id: required,
      from: required,
      to: required,
    }),

    // Not Restful
    getPointData: httpGetMethod(dynamicUrl`${EndPoints.Analytics}/api/${PARAM_ID}/Data/Point`, {
      _id: required,
      from: required,
      to: required,
    }),
    getDataSession: httpGetMethod(dynamicUrl`${EndPoints.FotaApp}/api/${PARAM_ID}/Data/Session`, {
      _id: required,
      from: required,
      to: required,
    }),
    linkAsset: httpPostMethod(dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/assets`, {
      _id: required,
      description: required,
      entityKey: required,
      identifier: required,
    }),
    createUnitProductionTime: httpPostMethod(
      dynamicUrl`${EndPoints.Management}/api/Units/${PARAM_ID}/ProductionTime`,
      {
        _id: required,
        installationDate: required,
      }
    ),
    getChargingSettingsThreshold: httpGetMethod(
      dynamicUrl`${EndPoints.Management}/api/Units/${PARAM_ID}/ChargingSettingsThreshold`,
      {
        _id: required,
      }
    ),
    createThresholdLink: httpPostMethod(
      dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/thresholds`,
      {
        _id: required,
        entityKey: required,
      }
    ),
    deleteThresholdLink: httpDeleteMethod(
      dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/thresholds/${"${thresholdEntityKey}"}`,
      {
        _id: required,
        thresholdEntityKey: required,
      }
    ),
    editThresholdLinks: httpPutMethod(
      dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/thresholds`,
      {
        _id: required,
        thresholdList: required,
      }
    ),
    getLatestConectivityStatus: httpGetMethod(
      dynamicUrl`${EndPoints.Analytics}/api/Units/${PARAM_ID}/Status/Connectivity/latest`,
      {
        _id: required,
      }
    ),
    editLinkAsset: httpPostMethod(dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/assets`, {
      _id: required,
      description: required,
      entityKey: required,
      identifier: required,
    }),
  },
  users: {
    list: httpGetMethod(EndPoints.Management + "/users"),
    getById: httpGetMethod(dynamicUrl`${EndPoints.Management}/users/${PARAM_ID}`, {
      _id: required,
    }),
    create: httpPostMethod(EndPoints.Management + "/users", {
      name: optional,
      description: optional,
      emailAddress: required,
      identifier: required,
      password: required,
      phoneNumber: optional,
    }),
    delete: httpDeleteMethod(dynamicUrl`${EndPoints.Management}/users/${PARAM_ID}`, {
      _id: required,
    }),
    getByCompanyId: httpGetMethod(dynamicUrl`${EndPoints.Management}/companies/${PARAM_ID}/users`, {
      _id: required,
    }),
    edit: httpPatchMethod(dynamicUrl`${EndPoints.Management}/users/${PARAM_ID}`, {
      _id: required,
      identifier: required,
      description: optional,
      name: optional,
      emailAddress: required,
      phoneNumber: optional,
    }),
    createRoleLink: httpPostMethod(dynamicUrl`${EndPoints.Management}/users/${PARAM_ID}/roles`, {
      _id: required,
      entityKey: required,
    }),
    deleteRoleLink: httpDeleteMethod(
      dynamicUrl`${EndPoints.Management}/users/${PARAM_ID}/roles/${"${roleEntityKey}"}`,
      {
        _id: required,
        roleEntityKey: required,
      }
    ),

    // Not Restful
    editRoleAssignments: httpPutMethod(
      dynamicUrl`${EndPoints.Management}/users/${PARAM_ID}/roles`,
      {
        _id: required,
        userRoleLinkList: required,
      }
    ),
    getByRoleEntityKey: httpGetMethod(dynamicUrl`${EndPoints.Management}/roles/${PARAM_ID}/users`, {
      _id: required,
    }),
  },
  userFederatedAuth: {
    refreshToken: null,
    register: httpPostMethod(dynamicUrl`${EndPoints.FederatedAuth}/register`, {
      firstName: required,
      lastName: required,
      email: required,
      password: required,
    }),
    getToken: httpPostMethod(dynamicUrl`${EndPoints.FederatedAuth}/authorize`, {
      email: required,
      password: required,
    }),
    changePassword: httpPutMethod(
      dynamicUrl`${EndPoints.FederatedAuth}/users/${PARAM_ID}/password`,
      {
        _id: required,
        emailAddress: required,
        currentPassword: required,
        newPassword: required,
      }
    ),
    refreshUserTokens: httpGetMethod(dynamicUrl`${EndPoints.FederatedAuth}/token`),
    linkRole: httpPostMethod(dynamicUrl`${EndPoints.Management}/users/${PARAM_ID}/roles`, {
      _id: required,
      role: required,
    }),
    newPasswordSet: httpPutMethod(EndPoints.FederatedAuth + "/user-password", {
      emailAddress: required,
      newPassword: required,
      otpVerificationCode: required,
    }),
    otpGet: httpPutMethod(EndPoints.FederatedAuth + "/otpcode", {
      emailAddress: required,
    }),
  },
  Error,
}
