const setPreferencesSets = (state, preferencesSets) => {
  state.preferencesSets = preferencesSets
}

const markPreferencesAdLoaded = (state) => {
  state.preferencesLoaded = true
}
const insertCardToCurrentSet = (state, card) => {
  state.preferencesSets.filter((p) => p.isSelected === true)[0].layout.cards.push(card)
}

const setSelectedPreferencesSets = (state, preferencesSetEntityKey) => {
  state.preferencesSets.forEach((val) => {
    if (val.entityKey !== preferencesSetEntityKey) {
      val.isSelected = false
    } else {
      val.isSelected = true
    }
  })
}

const addPreferencesSets = (state, preferencesSet) => {
  state.preferencesSets.push(preferencesSet)
}

export default {
  setPreferencesSets,
  markPreferencesAdLoaded,
  setSelectedPreferencesSets,
  addPreferencesSets,
  insertCardToCurrentSet,
}
