import { HubConnectionBuilder } from "@microsoft/signalr"
import Vue from "vue"
import Api from "../../api"
import Spec from "../../spec"
import router from "../../router"

const state = {
  connecting: 0,
  activeConnections: 0,
  notifications: [],
  latestReadings: {},
  bufferReadings: [],
  bufferTime: 1000,
  lastChange: 0,
  latestAlerts: {},
  latestSessions: {},
  latestStrokes: {},
  latestEvents: {},
  latestAllStrokesPerMinutes: {},
  latestEstimatedMaintenances: {},
  latestSinglePointsPressure: {},
  latestSinglePointsCompensatedPressure: {},
}

const getters = {}

const mutations = {
  connected(state, connection) {
    state.activeConnections++
    state.connecting--
  },
  failedConnection(state) {
    state.connecting--
  },
  newReading(state, jsonData) {
    const reading = JSON.parse(jsonData)
    const realtimeId = reading.unitId
    // If the firing event is a Stroke (MultiPoint pressure)
    // Convert the object to SinglePoint and remove irrelevant information.
    if (
      parseInt(reading.dataType) === Spec.DATA_MAPPING.Pressure &&
      reading.sessionReference !== undefined &&
      reading.sessionReference !== null
    ) {
      const unitId = reading.unitId
      reading.timestampCreated = reading.sessionStartTime
      reading.value = reading.minValue
      reading.axis = Spec.AXIS_MAPPING.NA
      delete reading.sessionStartTime
      delete reading.peakTimestamp
      delete reading.minValue
      delete reading.loQValue
      delete reading.avgValue
      delete reading.hiQValue
      delete reading.maxValue
      delete reading.avgMethod
      delete reading.sessionReference

      if (!state.latestSinglePointsPressure[unitId])
        Vue.set(state.latestSinglePointsPressure, unitId, {})

      Vue.set(state.latestSinglePointsPressure, unitId, reading)
    }

    if (parseInt(reading.dataType) === Spec.DATA_MAPPING.CompensatedPressure) {
      const readingPressure = JSON.parse(jsonData)
      const unitIdentifier = readingPressure.unitId

      if (!state.latestSinglePointsCompensatedPressure[unitIdentifier])
        Vue.set(state.latestSinglePointsCompensatedPressure, unitIdentifier, {})

      Vue.set(state.latestSinglePointsCompensatedPressure, unitIdentifier, readingPressure)
    }

    reading.timestampCreated = router.app.$getDateObjectWithTimeZone(reading.timestampCreated)

    if (parseInt(reading.dataType) !== Spec.DATA_MAPPING.RSSI) {
      if (!state.bufferReadings[realtimeId]) state.bufferReadings[realtimeId] = []
      state.bufferReadings[realtimeId].push(reading)
      const freeBuffer = function () {
        if (state.bufferReadings[realtimeId].length > 1) {
          const data = state.latestReadings
          if (!data[realtimeId]) Vue.set(data, realtimeId, {})
          Vue.set(data, realtimeId, state.bufferReadings[realtimeId])
          state.bufferReadings[realtimeId] = []
        }
      }
      setTimeout(freeBuffer, 1000)
    }
  },
  newAlert(state, jsonData) {
    const alert = JSON.parse(jsonData)
    alert.generatedDate = router.app
      .$getDateObjectWithTimeZone(alert.generatedDate)
      .format("YYYY-MM-DD HH:mm:ss")
    state.latestAlerts = alert
  },
  newSession(state, jsonData) {
    const session = JSON.parse(jsonData)
    const unitId = session.unitId
    session.sessionStartTime = router.app
      .$getDateObjectWithTimeZone(session.sessionStartTime)
      .format("YYYY-MM-DD HH:mm:ss")
    Vue.set(state.latestSessions, unitId, session)
  },
  newStroke(state, jsonData) {
    const stroke = JSON.parse(jsonData)
    const unitId = stroke.unitId
    stroke.peakTimestamp = router.app
      .$getDateObjectWithTimeZone(stroke.peakTimestamp)
      .format("YYYY-MM-DD HH:mm:ss")
    if (!state.latestStrokes[unitId]) Vue.set(state.latestStrokes, unitId, {})
    Vue.set(state.latestStrokes, unitId, stroke)
  },
  newEvent(state, jsonData) {
    const event = JSON.parse(jsonData)
    const unitId = event.unitId
    event.timestampCreated = router.app.$getDateObjectWithTimeZone(event.timestampCreated)
    Vue.set(state.latestEvents, unitId, event)
  },
  newStrokesPerMinute(state, jsonData) {
    const strokePerMinute = JSON.parse(jsonData)
    const unitId = strokePerMinute.unitId
    if (!state.latestAllStrokesPerMinutes[unitId])
      Vue.set(state.latestAllStrokesPerMinutes, unitId, {})
    Vue.set(state.latestAllStrokesPerMinutes, unitId, strokePerMinute)
  },
  newUnit(state, jsonData) {
    const unit = router.app.$getUnitDetails(JSON.parse(jsonData), true)
    const unitIndex = this.state.database.units.array.findIndex(
      (u) => u.entityKey === unit.entityKey
    )

    Vue.set(this.state.database.units, "lastUnitUpdated", unitIndex)

    if (unitIndex > -1) Vue.set(this.state.database.units.array, unitIndex, unit)

    localStorage.setItem(
      `database_${this.getters.getUserCompanyUnifiedId}_units`,
      JSON.stringify(this.state.database.units.array)
    )
  },
  newEstimatedMaintenance(state, jsonData) {
    const estimatedMaintenance = JSON.parse(jsonData)
    const unitId = estimatedMaintenance.unitIdsession
    estimatedMaintenance.calculationTime = router.app
      .$getDateObjectWithTimeZone(estimatedMaintenance.calculationTime)
      .format("YYYY-MM-DD HH:mm:ss")
    if (!state.latestEstimatedMaintenances[unitId])
      Vue.set(state.latestEstimatedMaintenances, unitId, {})
    Vue.set(state.latestEstimatedMaintenances, unitId, estimatedMaintenance)
  },
  newNotification(state, notification) {
    const previous = state.notifications[state.notifications.length - 1]
    if (!previous || previous.timestamp < notification.timestamp) {
      state.notifications.push(notification)
      M.toast({
        html: notification.message,
      })
    }
  },
}

const actions = {
  openRealtimeConnection(store) {
    Api.signalr.access().then((result) => {
      const state = store.state
      if (state.activeConnections > 0 || state.connecting > 0) return
      if (!result || !result.data) return
      let hubs = result.data
      if (!(hubs instanceof Array)) hubs = [hubs]
      const usedUrls = {}
      hubs.forEach((hub) => {
        if (usedUrls[hub.url]) return
        usedUrls[hub.url] = true
        const options = {
          accessTokenFactory: () => hub.accessToken,
          skipNegotiation: true,
          transport: 1,
        }
        state.connecting++
        // TODO: This is a serverless approach with the Azure SignalR service
        // It it's kind of hacky, and forcing a websocket connection
        // It should be reviewed
        const connection = new HubConnectionBuilder()
          .withUrl(hub.url, options)
          .withAutomaticReconnect()
          .build()

        connection.on("newReading", (jsonData) => {
          store.commit("newReading", jsonData)
        })
        connection.on("newEvent", (jsonData) => {
          store.commit("newEvent", jsonData)
        })
        connection.on("newAlert", (jsonData) => {
          store.commit("newAlert", jsonData)
        })
        connection.on("newNotification", (type, message, timestamp) => {
          store.commit("newNotification", { type, message, timestamp })
        })
        connection.on("newSessionMetadata", (jsonData) => {
          store.commit("newSession", jsonData)
        })
        connection.on("newStroke", (jsonData) => {
          store.commit("newStroke", jsonData)
          store.commit("newReading", jsonData)
        })
        connection.on("newStrokesPerMinute", (jsonData) => {
          store.commit("newStrokesPerMinute", jsonData)
        })
        connection.on("newEstimatedMaintenance", (jsonData) => {
          store.commit("newEstimatedMaintenance", jsonData)
        })
        connection.on("newUnit", (jsonData) => {
          store.commit("newUnit", jsonData)
        })
        connection
          .start()
          .then(() => store.commit("connected", connection))
          .catch(() => store.commit("failedConnection"))
        connection.onclose(function () {
          location.reload()
        })
      })
    })
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
