import Api from "../../api"
import router from "../../router"

const SYNC_MODE_FULL = 1
// const SYNC_MODE_ADD = 2         not implemented
const SYNC_MODE_DELETE = 3
const LOCALSTORAGE_PREFIX = "database_"

const state = {
  units: {
    realm: null,
    fetching: false,
    array: [],
    map: {},
  },
  assets: {
    realm: null,
    fetching: false,
    array: [],
    map: {},
  },
  notifications: {
    realm: null,
    fetching: false,
    array: [],
    map: {},
  },
  unifiedThresholds: {
    realm: null,
    fetching: false,
    array: [],
    map: {},
  },
  chargingSettings: {
    realm: null,
    fetching: false,
    array: [],
    map: {},
  },
  companyFeatures: {
    realm: null,
    fetching: false,
    array: [],
    map: {},
  },
  customUoms: {
    realm: null,
    fetching: false,
    array: [],
    map: {},
  },
  userPreferences: {
    realm: null,
    fetching: false,
    array: [],
    map: {},
  },
}

const getters = {
  companyFeatureStatus: (state) => (featureName) => {
    const record = state.companyFeatures.array.filter(
      (cf) => cf.feature.toLowerCase() === featureName.toLowerCase()
    )
    if (record) {
      if (record[0] !== undefined && record[0] !== null) {
        return record[0].isEnabled
      } else return false
    } else return false
  },
  customUoms(state) {
    return state.customUoms.array
  },
  insights(state) {
    return state.notifications.array.sort(function (a, b) {
      return new Date(b.generatedDate) - new Date(a.generatedDate)
    })
  },
  unifiedThresholds(state) {
    return state.unifiedThresholds.array
  },
  userPreferences(state) {
    return state.userPreferences.array
  },
}

const mutations = {
  setDatabase(state, { type, array }) {
    if (!array) return
    if (state[type].array.length > 0) return // It has already been initialized
    state[type].array = array
    state[type].map = {}
    const map = state[type].map
    for (let position = 0; position < array.length; position++) {
      const id = array[position].$ID
      map[id] = {
        position,
      }
    }
  },
  syncDatabase(state, { mode, type, remoteObjects }) {
    const map = state[type].map
    const array = state[type].array
    const realm = state[type].realm
    const updatePositions = function () {
      for (let position = 0; position < array.length; position++) {
        const id = array[position].$ID
        if (map[id]) {
          map[id].position = position
        } else {
          array.splice(position, 1)
          position--
        }
      }
    }
    const savePersistent = function () {
      if (!realm) return
      localStorage.setItem(LOCALSTORAGE_PREFIX + realm + "_" + type, JSON.stringify(array))
    }
    if (mode === SYNC_MODE_DELETE) {
      remoteObjects.forEach((remoteObject) => {
        const id = remoteObject.$ID
        if (map[id]) {
          delete map[id]
        }
      })
      updatePositions()
      savePersistent()
      return
    }
    Object.keys(map).forEach((key) => {
      map[key].renewed = false
    })
    remoteObjects.forEach((remoteObject) => {
      const id = remoteObject.$ID
      if (map[id]) {
        const position = map[id].position
        array[position] = Object.assign(array[position], remoteObject)
        map[id].renewed = true
      } else {
        map[id] = {
          renewed: true,
          position: array.length,
        }
        array.push(remoteObject)
      }
    })
    if (mode === SYNC_MODE_FULL) {
      Object.keys(map).forEach((key) => {
        if (!map[key].renewed) {
          delete map[key]
        }
      })
      updatePositions()
    }
    savePersistent()
  },
  fetchStatusChanged(state, { type, status }) {
    state[type].fetching = status
  },
  databaseError(state, error) {},
}
const loadFromLocalStorage = function (store, type) {
  const realm = store.getters.getUserCompanyUnifiedId
  if (!realm) return
  if (state[type].realm === realm) return
  state[type].realm = realm
  state[type].array = []
  state[type].map = {}
  const persistent = localStorage.getItem(LOCALSTORAGE_PREFIX + realm + "_" + type)
  if (!persistent) return
  try {
    const array = JSON.parse(persistent)
    store.commit("setDatabase", { type, array })
  } catch (e) {}
}

const actions = {
  async refreshUserPreferences(store) {
    const type = "userPreferences"
    loadFromLocalStorage(store, type)
    store.commit("fetchStatusChanged", { type, status: true })
    await Api.preferencesSet
      .getByUserId({
        _id: store.getters.getUserEntityKey,
      })
      .then((response) => {
        store.commit("setUserPreferencesEntityKey", response.data.entityKey)
        const mode = SYNC_MODE_FULL
        const remoteObjects = response.data.preferences

        for (let objectIndex = 0; objectIndex < remoteObjects.length; objectIndex++) {
          const pref = remoteObjects[objectIndex]
          pref.$ID = pref.entityKey
        }
        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.commit("databaseError", error)
      })
      .finally(() => {
        store.commit("fetchStatusChanged", { type, status: false })
      })
  },
  async refreshUnits(store) {
    const type = "units"
    loadFromLocalStorage(store, type)
    store.commit("fetchStatusChanged", { type, status: true })
    await Api.units
      .detailedList({
        showDetailed: true,
      })
      .then((response) => {
        const mode = SYNC_MODE_FULL
        const remoteObjects = response.data

        for (let objectIndex = 0; objectIndex < remoteObjects.length; objectIndex++) {
          router.app.$getUnitDetails(remoteObjects[objectIndex], false)
        }

        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.commit("databaseError", error)
      })
      .finally(() => {
        store.commit("fetchStatusChanged", { type, status: false })
      })
  },
  async refreshAssets(store) {
    const type = "assets"
    loadFromLocalStorage(store, type)
    store.commit("fetchStatusChanged", { type, status: true })
    await Api.assets
      .list()
      .then((response) => {
        const mode = SYNC_MODE_FULL
        const remoteObjects = response.data

        for (let objectIndex = 0; objectIndex < remoteObjects.length; objectIndex++) {
          const asset = remoteObjects[objectIndex]
          asset.$ID = asset.entityKey
          asset.isnBsCount = 0
          asset.isnLiteCount = 0
          asset.isnCount = 0
          asset.lowBatteryDevices = 0
          asset.linkedDevices = asset.unitAssignments.length
          asset.linkedEntityKeys = []
          asset.linkedIdentifiers = []
          asset.lowBatteryDevicesList = []

          for (let i = 0; i < asset.unitAssignments.length; i++) {
            if (asset.unitAssignments[i].unit.type === "ISNBS") asset.isnBsCount++

            if (asset.unitAssignments[i].unit.type === "ISN") asset.isnCount++

            if (asset.unitAssignments[i].unit.type === "ISNLite") asset.isnLiteCount++

            if (asset.unitAssignments[i].unit.batteryUsage > 87) {
              asset.lowBatteryDevices++
              asset.lowBatteryDevicesList.push(asset.unitAssignments[i].unit.identifier)
            }

            asset.linkedEntityKeys.push(asset.unitAssignments[i].unit.entityKey)
            asset.linkedIdentifiers.push(asset.unitAssignments[i].unit.identifier)
          }
        }
        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.commit("databaseError", error)
      })
      .finally(() => {
        store.commit("fetchStatusChanged", { type, status: false })
      })
  },
  deleteAssetData(store, data) {
    if (!data || !data.asset) return
    const asset = data.asset

    Api.assets
      .delete({
        _id: asset.entityKey,
      })
      .then(() => {
        const type = "assets"
        const mode = SYNC_MODE_DELETE
        const remoteObjects = [asset]
        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.dispatch("logErrorMessage", { error, display: true })
      })
  },
  refreshNotifications(store) {
    const type = "notifications"
    loadFromLocalStorage(store, type)
    store.commit("fetchStatusChanged", { type, status: true })
    Api.alertStatus
      .list()
      .then((response) => {
        const mode = SYNC_MODE_FULL
        const remoteObjects = response.data

        for (let objectIndex = 0; objectIndex < remoteObjects.length; objectIndex++) {
          router.app.$getInsightDetails(remoteObjects[objectIndex])
        }
        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.commit("databaseError", error)
      })
      .finally(() => {
        store.commit("fetchStatusChanged", { type, status: false })
      })
  },
  deleteNotificationData(store, data) {
    if (!data || !data.notification) return
    const notification = data.notification

    Api.alertStatus
      .delete({
        _id: notification.entityKey,
      })
      .then(() => {
        const type = "notifications"
        const mode = SYNC_MODE_DELETE
        const remoteObjects = [notification]
        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.dispatch("logErrorMessage", { error, display: true })
      })
  },
  refreshUnifiedThresholds(store) {
    const type = "unifiedThresholds"
    loadFromLocalStorage(store, type)
    store.commit("fetchStatusChanged", { type, status: true })
    Api.unifiedThresholds
      .list()
      .then((response) => {
        const mode = SYNC_MODE_FULL
        const remoteObjects = response.data

        for (let objectIndex = 0; objectIndex < remoteObjects.length; objectIndex++) {
          const unifiedThreshold = remoteObjects[objectIndex]
          unifiedThreshold.$ID = unifiedThreshold.entityKey
        }
        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.commit("databaseError", error)
      })
      .finally(() => {
        store.commit("fetchStatusChanged", { type, status: false })
      })
  },
  deleteUnifiedThreshold(store, data) {
    if (!data || !data.threshold) return
    const unifiedThreshold = data.threshold
    Api.unifiedThresholds
      .delete({
        _id: unifiedThreshold.entityKey,
      })
      .then(() => {
        const type = "unifiedThresholds"
        const mode = SYNC_MODE_DELETE
        const remoteObjects = [unifiedThreshold]
        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.dispatch("logErrorMessage", { error, display: true })
      })
  },
  refreshCompanyFeatures(store, companyEntityKey) {
    const type = "companyFeatures"
    loadFromLocalStorage(store, type)
    store.commit("fetchStatusChanged", { type, status: true })
    Api.companyFeatures
      .getCompanyFeatures({
        _id: companyEntityKey,
      })
      .then((response) => {
        const mode = SYNC_MODE_FULL
        const remoteObjects = response.data

        for (let objectIndex = 0; objectIndex < remoteObjects.length; objectIndex++) {
          const feature = remoteObjects[objectIndex]
          feature.$ID = feature.entityKey
        }
        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.commit("databaseError", error)
      })
      .finally(() => {
        store.commit("fetchStatusChanged", { type, status: false })
      })
  },
  refreshCustomUoms(store) {
    const type = "customUoms"
    loadFromLocalStorage(store, type)
    store.commit("fetchStatusChanged", { type, status: true })
    Api.customUoms
      .list()
      .then((response) => {
        const mode = SYNC_MODE_FULL
        const remoteObjects = response.data

        for (let objectIndex = 0; objectIndex < remoteObjects.length; objectIndex++) {
          const customUom = remoteObjects[objectIndex]
          customUom.$ID = customUom.entityKey
        }
        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.commit("databaseError", error)
      })
      .finally(() => {
        store.commit("fetchStatusChanged", { type, status: false })
      })
  },
  deleteCustomUomData(store, data) {
    if (!data || !data.customUom) return
    const customUom = data.customUom

    Api.customUoms
      .delete({
        _id: customUom.entityKey,
      })
      .then(() => {
        const type = "customUoms"
        const mode = SYNC_MODE_DELETE
        const remoteObjects = [customUom]
        store.commit("syncDatabase", { mode, type, remoteObjects })
      })
      .catch((error) => {
        store.dispatch("logErrorMessage", { error, display: true })
      })
  },
}
export default {
  state,
  mutations,
  actions,
  getters,
}
