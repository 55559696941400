const state = {
  dashboard: {
    expandedAssets: [],
  },
}

const mutations = {
  addExpandedAsset(state, item) {
    if (state.dashboard.expandedAssets && state.dashboard.expandedAssets.length > 0) {
      const companySettings = state.dashboard.expandedAssets.find(
        (c) => c.companyName === item.companyName
      )
      if (companySettings) {
        const tabs = state.dashboard.expandedAssets.find(
          (c) => c.companyName === item.companyName
        ).arrayExpandedTabs

        if (tabs) {
          state.dashboard.expandedAssets
            .find((c) => c.companyName === item.companyName)
            .arrayExpandedTabs.push({
              assetEntityKey: item.tab.assetEntityKey,
              tab: item.tab.tabIndex,
            })
        } else {
          const arrayExpandedTabs = []
          arrayExpandedTabs.push({
            assetEntityKey: item.tab.assetEntityKey,
            tab: item.tab.tabIndex,
          })
          state.dashboard.expandedAssets.find(
            (c) => c.companyName === item.companyName
          ).arrayExpandedTabs = arrayExpandedTabs
        }
      } else {
        const firstItem = { companyName: item.companyName, arrayExpandedTabs: [] }
        firstItem.arrayExpandedTabs.push({
          assetEntityKey: item.tab.assetEntityKey,
          tab: item.tab.tabIndex,
        })
        state.dashboard.expandedAssets.push(firstItem)
      }
    } else {
      const itemToCreate = { companyName: item.companyName, arrayExpandedTabs: [] }
      itemToCreate.arrayExpandedTabs.push({
        assetEntityKey: item.tab.assetEntityKey,
        tab: item.tab.tabIndex,
      })
      state.dashboard.expandedAssets.push(itemToCreate)
    }
    localStorage.setItem("siteComponents", JSON.stringify(state.dashboard.expandedAssets))
  },
  removeExpandedAsset(state, item) {
    if (state.dashboard.expandedAssets && state.dashboard.expandedAssets.length > 0) {
      const companySettings = state.dashboard.expandedAssets.find(
        (c) => c.companyName === item.companyName
      )
      if (companySettings) {
        const position = companySettings.arrayExpandedTabs.findIndex(
          (i) => i.assetEntityKey === item.tab.assetEntityKey
        )
        if (position !== -1) {
          state.dashboard.expandedAssets
            .find((c) => c.companyName === item.companyName)
            .arrayExpandedTabs.splice(position, 1)

          localStorage.setItem("siteComponents", JSON.stringify(state.dashboard.expandedAssets))
        }
      }
    }
  },
  updateExpandedAssets(state, items) {
    state.dashboard.expandedAssets = items
  },
}

const actions = {
  addItemToExpandedAssets({ commit, rootGetters }, item) {
    const companyName = rootGetters.currentCompany.companyName
    commit("addExpandedAsset", { companyName: companyName, tab: item })
  },
  removeItemFromExpandedAssets({ commit, rootGetters }, item) {
    const companyName = rootGetters.currentCompany.companyName
    commit("removeExpandedAsset", { companyName: companyName, tab: item })
  },
  refreshExpandedItems({ commit }) {
    let stored = localStorage.getItem("siteComponents")
    if (!stored || stored === "") stored = "[]"
    const parsedMessage = JSON.parse(stored)
    commit("updateExpandedAssets", parsedMessage)
  },
}

const getters = {
  expandedAssets(state, rootGetters) {
    const companyName = rootGetters.currentCompany.companyName
    if (state.dashboard.expandedAssets && state.dashboard.expandedAssets.length > 0) {
      const companySettings = state.dashboard.expandedAssets.find(
        (c) => c.companyName === companyName
      )
      if (companySettings) return companySettings.arrayExpandedTabs.map((t) => t.tab)
      else return []
    } else return []
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
